<template>
  <multiselect
    class="with-border"
    track-by="id"
    label="name"
    :value="item"
    :searchable="true"
    :showLabels="false"
    :allow-empty="false"
    placeholder="Selecionar..."
    :options="items"
    :disabled="disabled"
    @select="onSelect"
    @search-change="debounceItems"
  >
    <template slot="caret">
      <div v-if="!item" class="chevron">
        <ChevronDown />
      </div>
    </template>
    <template slot="singleLabel" slot-scope="{ option }">
      <ItemLine :item="option" />
    </template>
    <template slot="option" slot-scope="props">
      <ItemLine :item="props.option"/>
      <p class="disabled-description" v-if="props.option.$isDisabled">
        Este procedimento é realizado em outra sala, crie um novo agendamento para adicioná-lo.
      </p>
    </template>
    <template slot="noResult">{{noResultMessage}}</template>
    <template slot="noOptions">
      Digite para pesquisar um procedimento
    </template>
  </multiselect>
</template>
<script>
import { debounce } from 'lodash';
import { getCurrentClinic } from '@/utils/localStorageManager'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import ItemLine from '@/components/General/ItemLine'

export default {
  components: { ChevronDown, ItemLine },
  props: {
    item: Object,
    disabled: Boolean,
    availableProcedures: Array,
    noResultMessage: String
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      debounceItems: debounce(this.searchItems, 300),
      items: [],
    }
  },
  methods: {
    onSelect(item) {
      this.$emit('select', item)
    },
    searchItems(query) {
      this.api.getItems(this.clinic.id, query)
      .then(res => {
        this.items = res.data
        // this.disableProcedures()
      })
      .catch(err => this.$toast.error(err.message))
    },
    disableProcedures() {
      if (!this.availableProcedures || !this.availableProcedures.length) return
      this.items = this.items.map(el => ({ ...el, $isDisabled: this.item !== el.id && el.item_type === 'App\\Models\\ClinicProcedure' && !this.availableProcedures.some(av => av.clinic_procedure_id === el.id)}))
    },
  },
}
</script>
<style lang="scss" scoped>
.disabled-description {
  font-size: 14px;
  font-style: italic;
  color: var(--type-active);
}
</style>
